import { BalHeading, BalText } from "@baloise/ds-react";
import {
    InsurancePackageType,
    OptionalGuaranteeType,
} from "@baloise-cfa/tsclient/mob";
import { useMediaQuery } from "@lg-cfa/hooks";
import { MediaQuery } from "@lg-cfa/interfaces-enums";
import { formatPrice } from "@lg-cfa/utils";
import classnames from "classnames";
import { graphql, navigate } from "gatsby";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import {
    Card,
    InsuranceChoiceModal,
    InsuranceChoiceModalFormValues,
    quoteFormState,
    QuoteLayout,
    useQuoteInsurance,
} from "@modules/quote";
import {
    DiscountBlock,
    Footer,
    InsuranceContactBlock,
    Loading,
    Navigation,
    Summary,
} from "@modules/shared/components";
import { CarType } from "@modules/shared/enums";
import { getNavigationPages } from "@modules/shared/helpers";
import { AppState, appState } from "@modules/shared/state";
import { PageProps } from "@modules/shared/types";

const InsuranceChoicePage: React.FC<PageProps> = ({ pageContext }) => {
    const { foundPage: page, ownPageObjects: allPages, language } = pageContext;
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState<boolean>(false);
    const appData = useRecoilValue<AppState>(appState);
    const quoteData = useRecoilValue(quoteFormState);
    const [insuranceChoice, setInsuranceChoice] =
        useState<InsuranceChoiceModalFormValues>({
            insurance:
                quoteData?.insurances?.insurance ??
                appData?.InsuranceChoice?.insurance,
            extraOptions: quoteData?.insurances?.insurance?.optionalGuarantees
                ?.length
                ? quoteData?.insurances?.insurance?.optionalGuarantees.map(
                      (option) => option.type as OptionalGuaranteeType,
                  )
                : appData?.InsuranceChoice?.extraOptions ?? [],
        });
    const { insurance, insurances, bonusMalus, loading } = useQuoteInsurance(
        language,
        insuranceChoice.insurance,
        insuranceChoice.extraOptions,
    );
    const { nextPage, prevPage } = getNavigationPages(allPages, page);
    const isMobile = useMediaQuery(MediaQuery.Mobile);

    const renderInsurancePriceBlock = (
        packageType?: InsurancePackageType | OptionalGuaranteeType,
        price?: number,
        isOption?: boolean,
    ) => (
        <div className="flex justify-content-space-between align-items-flex-start mb-small">
            {packageType && (
                <>
                    <div className="flex align-items-center gap-small">
                        <svg
                            width="16"
                            height="11"
                            viewBox="0 0 16 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15 2.03125L6.5 10.5312C6.375 10.6875 6.1875 10.75 6 10.75C5.78125 10.75 5.59375 10.6875 5.46875 10.5312L0.96875 6.03125C0.65625 5.75 0.65625 5.28125 0.96875 5C1.25 4.6875 1.71875 4.6875 2 5L6 8.96875L13.9688 1C14.25 0.6875 14.7188 0.6875 15 1C15.3125 1.28125 15.3125 1.75 15 2.03125Z"
                                fill="#102B4E"
                            />
                        </svg>
                        <BalText space="none">
                            {isOption
                                ? t(
                                      `insurance.extraOptions.${packageType.toLowerCase()}`,
                                  )
                                : t(
                                      `insurance.types.${packageType.toLowerCase()}.name`,
                                  )}
                        </BalText>
                    </div>
                    <div className="flex flex-direction-column align-items-end">
                        <BalText bold space="none">
                            {formatPrice.format(price ?? 0)}
                        </BalText>
                        <BalText color="grey" size="small" space="none">
                            {t("all.payment.yearly")}
                        </BalText>
                    </div>
                </>
            )}
        </div>
    );

    return (
        <>
            {loading && <Loading translationKey="loading.tariffs.text" />}
            <QuoteLayout
                title={t("quote.insurances.title")}
                page={page}
                allPages={allPages}
                language={language}
            >
                <div className="container my-large is-compact flex flex-direction-column align-items-center">
                    <BalHeading level="h1" subtitle>
                        {t("quote.insurances.title")}
                    </BalHeading>
                    <BalText>{t("quote.insurances.intro")}</BalText>

                    <Card
                        title={
                            t("quote.insurances.chosenInsurances.title") ?? ""
                        }
                        wrapperClass="mb-xx-large p-large"
                        onEdit={() => setShowModal(true)}
                    >
                        <>
                            {renderInsurancePriceBlock(
                                insurance?.type,
                                insurance?.price,
                            )}

                            {insurance?.optionalGuarantees?.map((option) => {
                                return (
                                    <Fragment key={option.type}>
                                        {renderInsurancePriceBlock(
                                            option?.type,
                                            option?.price,
                                            true,
                                        )}
                                    </Fragment>
                                );
                            })}
                        </>
                    </Card>

                    <InsuranceContactBlock
                        title="quote.insurances.advice.title"
                        description="insurances.advice.description"
                    />
                </div>
                <DiscountBlock
                    driversLicenseDate={appData?.Driver?.license}
                    hasFormula36={!!quoteData?.insurances?.insurance?.formula}
                    carRegistrationDate={appData.CarUsage?.registrationdate}
                    carType={appData.BeforeWeStart?.cartype as CarType}
                    bonusMalus={bonusMalus}
                    mileage={appData.CarUsage?.mileage}
                    amountOfSafetyOptions={
                        appData.CarDescription?.safetyOptions?.length ?? 0
                    }
                />
                <Summary
                    chosenInsurance={insurance}
                    chosenOptions={insurance?.optionalGuarantees}
                >
                    <div
                        className={classnames(
                            "flex",
                            {
                                "justify-content-end": !isMobile,
                            },
                            {
                                "flex-direction-column-reverse": isMobile,
                            },
                        )}
                    >
                        <Navigation
                            t={t}
                            language={language}
                            prevPage={prevPage}
                            nextPage={nextPage}
                            prevPageBtnText="all.edit"
                            onNextClick={() =>
                                navigate(nextPage?.paths[language] ?? "")
                            }
                            prevColor="secondary"
                            prevIcon="edit"
                            nextColor="primary"
                            inverted
                        />
                    </div>
                    <Footer isDark isCompact />
                </Summary>

                {showModal && insurances && insurance ? (
                    <InsuranceChoiceModal
                        insurance={insurance}
                        insurances={insurances}
                        onSubmit={(values) => {
                            setInsuranceChoice(
                                values as InsuranceChoiceModalFormValues,
                            );
                            setShowModal(false);
                        }}
                        onClose={() => setShowModal(false)}
                    ></InsuranceChoiceModal>
                ) : null}
            </QuoteLayout>
        </>
    );
};

export default InsuranceChoicePage;

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
